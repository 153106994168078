
import { Component, Vue } from 'vue-property-decorator';
import RegistrationFormRenderer from '@/components/RegistrationFormRenderer.vue';
import { AUTH_SRC } from '@/constants';
import { loadRegistrationFormRenderer } from '@/api/admin-registration.api';
import {
  addApiRegistrationVersion,
  getApiRegistration,
  getListingForRegisterId,
  isUpstreamUriUnique,
  registerApi,
  updateApiRegistration,
} from '@/api/registration.api';
import { get } from 'lodash';
import { DynamicRegFormModule } from '@/store/modules/dynamic-registration.module';
import { AppEnum, ensureAppEnum, Status } from '@/types/enum.types';
import { Location } from 'vue-router';
import { RouteNames } from '@/constants';
import { openErrorSnackbar, openSnackbar, openWarningSnackbar } from '@/utils/components';
import { ProjectDetailsModule } from '@/store/modules/project-details.module';
import { EnumsModule } from '@/store/modules/enums.module';
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue';
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import DOMPurify from 'dompurify';

@Component({
  components: {
    DeleteDialog,
    RegistrationFormRenderer,
    ConfirmDialog,
  },
})
export default class NewApiRegistration extends Vue {
  formRendererData = '';
  loadingRegistration = false;
  loadingFormRenderer = true;
  apiRegistrationProcessing = false;
  registrationVersionId = null;
  private confirmRegistrationOpen = false;
  confirmRegistrationDialogText = '';
  oldUpstreamUri = '';

  get env(): AppEnum {
    const penvId = this.$route.params.env;
    const envId = ProjectDetailsModule.projectEnvsByProjectEnvId[penvId]?.environmentId ?? -1;
    return ensureAppEnum(EnumsModule.enumsById.environment[envId]);
  }

  get oauthMnemonic() {
    const projectId = (this.$route.params && +this.$route.params.id) || undefined;
    const projectEnvId = +this.$route.params.env;
    if (projectId) {
      if (
        ProjectDetailsModule.oAuthMneumonics[projectId] &&
        ProjectDetailsModule.oAuthMneumonics[projectId][projectEnvId]?.oAuthType
      ) {
        return ProjectDetailsModule.oAuthMneumonics[projectId][projectEnvId]?.oAuthType;
      } else if (ProjectDetailsModule.envs[projectId][projectEnvId]?.auth.OAuth.oauthType) {
        return ProjectDetailsModule.envs[projectId][projectEnvId]?.auth.OAuth.oauthType;
      }
    }
    return AUTH_SRC.PING_OAUTH.mnemonic;
  }

  get versions(): number[] {
    const projectId = +this.$route.params.id;
    const projectEnvId = +this.$route.params.env;

    return (
      ProjectDetailsModule.envs?.[projectId]?.[projectEnvId]?.registrations?.services
        ?.find(ss => ss.some(s => s.registrationVersionId === this.registrationVersionId))
        ?.filter(s => (this.isEdit ? s.registrationVersionId !== this.registrationVersionId : true))
        .map(s => s.version) ?? []
    );
  }

  get platformMnemonic() {
    return ProjectDetailsModule.projectDetails.platform?.mnemonic;
  }

  beforeDestroy() {
    DynamicRegFormModule.resetDeck();
  }

  async created() {
    DynamicRegFormModule.resetShowFieldsWithNames();
    DynamicRegFormModule.resetDeck();
    DynamicRegFormModule.resetResourceData();
    const projectId = (this.$route.params && +this.$route.params.id) || undefined;
    const projectEnvId = (this.$route.params && +this.$route.params.env) || undefined;
    if (projectId && projectEnvId) {
      await Promise.all([
        ProjectDetailsModule.getProject(projectId),
        ProjectDetailsModule.getProjectEnvironmentPart({
          projectId,
          projectEnvId,
          partName: 'registrations',
        }),
        ProjectDetailsModule.getProjectEnvironmentPart({
          projectId,
          projectEnvId,
          partName: 'auth',
        }),
      ]);
      await ProjectDetailsModule.allProjectEnvironmentUpstreamUri(projectEnvId);
    }
    // await ProjectDetailsModule.allProxyPath(this.platformMnemonic);
    DynamicRegFormModule.setOAuthMnemonicVal(this.oauthMnemonic);
    this.loadingFormRenderer = true;
    const response = await loadRegistrationFormRenderer();
    this.loadingFormRenderer = false;
    this.formRendererData = response.data;
    const serviceId = +this.$route.params.serviceId;
    if (this.isNewVersion) {
      if (!!this.env && this.env.id !== EnumsModule.enums.environment.DEV.id) {
        openWarningSnackbar.call(
          this,
          'You can only create a new version from a Dev environment API',
        );
        return this.$router.replace(this.projectRoute);
      }
    }
    if (this.isEdit || this.isNewVersion) {
      this.loadingRegistration = true;
      getApiRegistration(serviceId)
        .then((res: any) => {
          this.registrationVersionId = res.data.registrationVersionId;
          const services = res.data.deck.services;
          const [service] = services;
          this.oldUpstreamUri = res.data?.serviceProperties?.upstream_full_uri;
          DynamicRegFormModule.setResourceData({
            name: service?.customProps?.resourceName,
            path: service?.routes?.[0]?.paths?.[0],
            isEdit: this.isEdit,
            isPromote:false,
            versions: this.versions,
          });
          if (service?.customProps?.formMode === 'advanced') {
            DynamicRegFormModule.setSimpleMode(false);
          } else {
            DynamicRegFormModule.setSimpleMode(true);
          }

          DynamicRegFormModule.setDeckConfig(res.data.deck);
          DynamicRegFormModule.updateRegFormData();
        })
        .catch(() => {
          openErrorSnackbar.call(this, 'Server Error: Failed to load service definition');
          // this.$router.replace(this.projectRoute);
        })
        .finally(() => {
          this.loadingRegistration = false;
        });
    }
  }

  get deckConfig() {
    return DynamicRegFormModule.deckConfig;
  }

  get publishApiListing() {
    return DynamicRegFormModule.publishApiListingData;
  }

  get projectRoute(): Location {
    return {
      name: RouteNames.ProjectEnvDetails,
      params: {
        ...this.$route.params,
        section: 'registrations',
      },
      query: {},
    };
  }

  get isEdit(): boolean {
    return this.$route.name === RouteNames.RegisterApiNewEdit;
  }

  get isNewVersion(): boolean {
    return this.$route.name === RouteNames.RegisterApiNewVersion;
  }

  get tags(): string[] {
    return DynamicRegFormModule.deckConfig.services?.[0].tags || [];
  }
  get serviceName(): string | null {
    return DynamicRegFormModule.deckConfig.services?.[0].name || null;
  }
  get upstreamUri(): string {
    return `${DynamicRegFormModule.deckConfig.services?.[0].protocol}://${DynamicRegFormModule.deckConfig.services?.[0].host}${DynamicRegFormModule.deckConfig.services?.[0].path}`;
  }

  async submit(): Promise<void> {
    try {
      let reg;
      this.apiRegistrationProcessing = true;
      if (this.isNewVersion) {
        reg = await addApiRegistrationVersion({
          serviceId: this.$route.params.serviceId,
          versionId: this.deckConfig.services[0].customProps.version,
          data: { deck: this.deckConfig },
        }).then(r => r.data);
      } else if (this.isEdit) {
        reg = await updateApiRegistration({
          serviceId: this.$route.params.serviceId,
          data: {
            deck: this.deckConfig,
          },
        }).then(r => r.data);
      } else {
        reg = await registerApi({
          projectId: this.$route.params.id,
          data: {
            deck: this.deckConfig,
            publishListingOnRegistration: this.publishApiListing,
          },
        }).then(r => r.data);
      }

      if (reg) {
        const r = this.projectRoute;
        if (reg.statusMnemonic === Status.Provisioning) {
          r.query = { ...(r.query ?? {}), success: '1', isCustom: Number(reg.isCustom).toString() };
        }
        this.$router.replace(r);
      }
    } catch (e) {
      this.apiRegistrationProcessing = false;
      openSnackbar.call(
        this,
        `Server Error: API Registration Failed - ${(e as unknown as Error)?.message || ''}`,
        {
          type: 'error',
        },
      );
    }
  }

  async confirmSubmit(): Promise<void> {
    this.confirmRegistrationOpen = false;
    this.deckConfig.services[0].customProps.duplicatedUpstream = true;
    return this.submit();
  }

  get isEditUpstreamUri(): boolean {
    return this.isEdit ? this.upstreamUri !== this.oldUpstreamUri : true;
  }

  async onSubmit(): Promise<void> {
    if(!this.isEditUpstreamUri){
      return this.submit();
    }
    const registerIds: any = await isUpstreamUriUnique({upstreamUri: this.upstreamUri})

    if (registerIds?.data) {
      let listingForUpstream =await getListingForRegisterId(registerIds.data[0].registrationVersionId)

      if (listingForUpstream?.data) {
        const listing = listingForUpstream?.data.map((item: any) => {
          return `<li><a href='/listings/${item.listingId}' target="_blank">${item.listingName}</a></li>`
        }).join("")

        this.confirmRegistrationDialogText = DOMPurify.sanitize(`The API’s upstream URL you are trying to submit is already registered in Developer Portal.
        The following listing allows to connect to it: <br>
        <ul>
          ${listing}
        </ul>
        Please use it instead.`, {ALLOWED_TAGS: ['a', 'li', 'ul', 'br'],ALLOWED_ATTR: ['target', 'href']});

         this.confirmRegistrationOpen = true;
      } else {
        this.confirmRegistrationDialogText = `The API’s upstream URL you are trying to submit is already registered in Developer Portal.
        Please note this registration will induce charges and will have to be cost approved when going to PROD.`;
        this.confirmRegistrationOpen = true;
      }

    } else if(ProjectDetailsModule.upstreamUriList.includes(this.upstreamUri)) {
      this.confirmRegistrationDialogText = `The API’s upstream URL you are trying to register is already among your APIs.
      Please consider using previous instead of registering same one again.
      Please note this registration will induce charges and will have to be cost approved when going to PROD.`;
      this.confirmRegistrationOpen = true;
    }  else {
      return this.submit();
    }

  }
}
