
import { Component, Prop, Emit, Vue } from 'vue-property-decorator';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import GButton from '@/components/gsk-components/GskButton.vue';

@Component({
  components: {
    GDialog,
    GButton,
  },
})
export default class ConfirmDialog extends Vue {
  @Prop(Boolean) readonly open!: boolean;
  @Prop(Boolean) readonly noAction!: boolean;
  @Prop({ type: Boolean, default: false }) readonly loading!: boolean;
  @Prop({ type: String, default: 'Continue' }) readonly buttonText!: string;
  @Prop({ type: String, default: 'Discard' }) readonly cancelText!: string;

  @Emit('update:open')
  update(isOpen: boolean): boolean {
    return isOpen;
  }

  @Emit('confirm')
  confirm() {}

  cancel() {
    this.update(false);
  }
}
